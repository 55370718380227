import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, ExternalLink } from "react-feather"
import Urbandine from "../../images/urbandine.jpeg"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../../components/worldmap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class Evarvest extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEcosystemsLink: false,
      showNextLink: false,
      showPrevLink: false,
    }

    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    // Esc key press
    if (event.keyCode === 27) {
      this.setState({
        showEcosystemsLink: true,
      })

      setTimeout(() => {
        let linkRef = document.getElementById("esc-action")
        linkRef.click()
      }, 50)
    }

    // Left Arrow key press
    if (event.keyCode === 37) {
      this.setState({
        showPrevLink: true,
      })

      setTimeout(() => {
        let prevLinkRef = document.getElementById("prev-action")
        prevLinkRef.click()
      }, 50)
    }

    // Right Arrow key press
    if (event.keyCode === 39) {
      this.setState({
        showNextLink: true,
      })

      setTimeout(() => {
        let nextLinkRef = document.getElementById("next-action")
        nextLinkRef.click()
      }, 50)
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  render() {
    return (
      <Layout>
        {this.state.showEcosystemsLink && (
          <AniLink
            id="esc-action"
            fade
            to="/community"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showPrevLink && (
          <AniLink
            id="prev-action"
            fade
            to="/community"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showNextLink && (
          <AniLink
            id="next-action"
            fade
            to="/interviews/estonia"
            duration={0.4}
          ></AniLink>
        )}
        <SEO
          title="Jaikishen Jayesingha and Bhagya Goonewardhane: Founded Urbandine in the Netherlands"
          description="We spoke to Jaikishen Jayesingha, one of the Immigrant Founders from Sri Lanka that founded Urbandine in the Netherlands."
        />

        <div id="article-top">
          <div id="contains-map">
            <div id="netherlands_map_container">
              <WorldMap />
            </div>
          </div>
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
            <Mic className="page-icon" />
          </div> */}
              <h1 id="community-header">
                Jaikishen Jayesingha and Bhagya Goonewardhane: Founded Urbandine
                in the Netherlands
              </h1>
              <p className="description">
                This article was written by{" "}
                <OutboundLink
                  href="https://twitter.com/ziyadbasheer"
                  className="inline-link"
                  target="_blank"
                >
                  Ziyad Basheer{" "}
                </OutboundLink>
                based on an interview with the founder of Urbandine, Jaikishen
                Jayesingha
              </p>
              <article>
                <h3>Conceptualization</h3>
                <p>
                  We conceptualized Urbandine in Sri Lanka.  After about 7 to 9
                  months of iteration, we realized the Sri Lankan market didn't
                  fit what we were building and the problems we were trying to
                  solve, especially in terms of reaching a wider audience, and
                  our ambition drove us to look for markets which we could
                  potentially scale in.
                </p>
                <p>
                  We traveled to Singapore in May of 2018 and explored the
                  market to see whether it would be a good fit for Urbandine. We
                  met with restaurant owners and micro-VCs to understand the
                  investment landscape, and we found a few similar companies had
                  already raised their seed round of funding and were
                  operational during our time there. We also realized that
                  Singapore was a rather small but still a brilliant country to
                  start in.
                </p>

                <p>
                  Both Bhagya, my business partner, and I consider ourselves
                  global citizens. We've traveled extensively across the world
                  with our families as well as individually. When we felt that
                  Singapore was too small, we started exploring Europe because I
                  had studied in the UK and Bhagya had also traveled extensively
                  throughout Europe. We started researching the Netherlands,
                  Germany and other countries that we could potentially enter
                  with our immigration situation because, frankly, the Sri
                  Lankan passport is pretty bad. So we had to make sure that we
                  would be able to secure some form of immigration acceptance,
                  and after doing some research we realized that the Netherlands
                  startup visa was a good fit for us.
                </p>

                <p>
                  We were business partners and friends. And when we
                  individually explored Europe, we realized that the Netherlands
                  would be a good fit for what we were building. Because
                  whenever we walked around in Amsterdam, and looked at
                  restaurants we’d always see a “Help Wanted” sign that on the
                  side of the entry door — this indicated to us that there was a
                  labor problem in the Netherlands that needed addressing.
                </p>

                <p>
                  Specifically, we were looking to understand if there was a
                  startup visa for the countries we wanted to enter because
                  growing up in Sri Lanka and traveling as much as we did, it
                  was always governed by visas. It’s so hard to travel because
                  you need to get a visa for every single country. Even when I
                  did, I managed to travel overland in Africa through 10
                  different countries from South Africa to Djibouti by
                  Landcruiser. And even then, managing the visa process was so
                  hard, but we were fortunate to get through it.
                </p>

                <h3>Finding A Facilitator</h3>
                <p>
                  Before immigrating however, we needed to find a facilitator.
                  The facilitator takes care of the paperwork and ensures that
                  the founders and the startup are on the right track to setting
                  up shop. They basically need to give a guarantee to the
                  Immigration Department of the Netherlands that they’ve met
                  this individual or this company and these founders are sound
                  individuals who they can guarantee will try their best within
                  a year to grow their startup. The facilitator also makes sure
                  the founders are comfortable, have accommodation, are settled
                  in financially and tax-wise, and make sure that they are
                  incorporated in the Netherlands in terms of the company and in
                  terms of their own personal holding companies. They're also
                  advised but not required to help the company with their first
                  hiring, or their first few hires as well as opening an office
                  or finding an office where they can work out of.
                </p>
                <p>
                  Having the facilitator's support helped us move faster because
                  they gave us access to a network. They helped us quickly
                  incorporate and launch our business by taking care of legal
                  affairs, tax affairs and made sure we did all the things
                  right. The second primary benefit we gained from the
                  facilitators network was with regards to growing our business
                  — it was vital for sales as it helped us connect with the
                  right people to start getting our products out in front of
                  customers.
                </p>
                <h3>Working with Facilitators</h3>
                <p>
                  I first went to the Netherlands in December 2018 and started
                  meeting with facilitators. My goal was to find a facilitator
                  who would facilitate our entry into the Netherlands as a
                  startup led by myself and my co-founder. We met with a few and
                  eventually got selected to StartUp bootcamp in Amsterdam for
                  one of their e-commerce programs, however we turned them down
                  due to the need to give up equity to an accelerator and an
                  open offer from YES!Delft.
                </p>
                <p>
                  We were in a bit of an interesting situation. YES!Delft and
                  the Hague Business Agency had co-facilitated our entry.
                  YES!Delft provided the business acumen and the hand-holding
                  necessary to come to The Netherlands and actually grow a
                  startup. On the other hand, the Hague Business Agency just
                  handled the entry side of things initially. The Hague Business
                  Agency, facilitated our entry into the Hague market. And they
                  did that with the help of YES!Delft, because when we joined,
                  we got selected for the AI and Blockchain Validation Lab in
                  February the previous year. So due to that, we managed to
                  secure this startup visa facilitator, the Hague Business
                  Agency, as their first startup to facilitate. And since then
                  it's been great getting into the Netherlands and moving
                  forward on the startup journey.
                </p>
                <h3>The Visa Process</h3>
                <p>
                  We had to go to the Netherlands to meet with facilitators.
                  There are other ways where you can apply for programs remotely
                  and if you get accepted to the program, you can travel to the
                  Netherlands and then get a startup visa. However, the thing
                  is, coming from a developing country, it still takes time to
                  get even a tourist or visitor's visa and there's a lot of red
                  tape in the way.
                </p>
                <p>
                  Bhagya and I both have Sri Lankan passports, and the ranking
                  of the Sri Lankan passport has been depreciating like crazy
                  because a lot of Sri Lankan travel to other countries for
                  asylum — they just wanted to get out of Sri Lanka because it
                  was a part of a 30-year war and life was presented less
                  opportunity for a lot of people.
                </p>
                <p>
                  Generally speaking, in order to go to the Netherlands, you
                  should have a short-term visa, called the MVV. Have the
                  short-term visa with you so that you can stay in the
                  Netherlands for at least 3 months, and then you can switch
                  from the short-term visa to an actual Startup visa. Then,
                  basically, you can stay in the Netherlands for a year. And
                  after that, you can switch from a Startup visa to a 2-year
                  Self-Employment visa. And the facilitator makes that process
                  fairly straightforward.
                </p>
                <p>
                  The facilitator, through that year of getting to know you,
                  needs to guarantee that the startup has done what it's being
                  requested to do or that they've done enough to move to a
                  self-employment visa. Meaning that they would need to meet
                  certain requirements for the facilitator to write a letter to
                  the Immigration Department and state that the startup has been
                  backed by them and that the startup founder, myself for
                  instance, is eligible for the minimum number of points to get
                  a self-employment visa. The point system for the
                  self-employment visa isn't based solely on your performance in
                  the prior year. Your other credentials are also factored in.
                </p>
                <p>
                  It wasn't too difficult, all things considered. The primary
                  qualifier is just the fact that you are able to build an
                  innovative startup that could potentially make a difference in
                  the country. And if your startup is moving forward and getting
                  investment, then it makes sense for that facilitator to
                  finance the bridge from the startup visa to the
                  self-employment visa.
                </p>
                <h3>Thoughts on the Process</h3>
                <p>
                  From my point of view, being with a facilitator is going to
                  help you for a year or even more, sort of like an incubator.
                  And if you’re a legitimate startup founder, you will want to
                  take some time to understand who is the best facilitator for
                  your startup, whether it's for industry advice, or whether it
                  has to do with location, or networking.
                </p>
                <p>
                  There's a lot of different factors and, in my opinion, a
                  founder shouldn’t travel too much, but rather give the
                  facilitator peace of mind with face-to-face interaction.
                  Otherwise building your startup will be a hard thing to do.
                  The good thing about the startup visa program is that the
                  money you need to have for the first year, which is around
                  15,000 to 16,000 euros in the Netherlands, I believe, is
                  minimum wage. And the amount of money that you need to prove
                  that you have in your bank account doesn't necessarily need to
                  be an account in the Netherlands. The money can be in Sri
                  Lanka or whatever your country of origin is, and then you need
                  to be able to transfer that money accordingly. But even just
                  for proving purposes, the money can be in any other country.
                  So, that's a good thing that the Dutch Immigration Authority
                  allows.
                </p>
                <p>
                  Other than that, I believe it all comes down to your ambition.
                  If I had thought because of my experience dealing with visas
                  over the years that there's no chance I'm going to be able to
                  come to the Netherlands on a startup visa, I would have never
                  considered scaling Urbandine to Europe. It was my ambition
                  which drove me to speak with different stakeholders to
                  understand what the limitations are and then move forward and
                  make it happen.
                </p>
                <h3>How we Found a Facilitator</h3>
                <p>
                  So what I did was find a{" "}
                  <a href="https://english.rvo.nl/find-facilitator">
                    list of startup facilitators
                  </a>{" "}
                  in the Netherlands through their enterprise agency, the RVO,
                  website, which has a startup section on their website.
                </p>
                <p>
                  While the Netherlands is not a massive place, it has a large
                  number of cities with facilitators throughout big cities like
                  Amsterdam. I reached out to them by email and said, "Hey, we
                  have a startup pitch to re-conceptualize. We are looking to
                  move to the Netherlands. Is it possible to meet you?" So then,
                  a startup scout took a video call with us while we were still
                  in Sri Lanka. And the next stage was to potentially come and
                  meet them in the Netherlands. So, we met with the ones who we
                  had spoken with and scheduled meetings in the Netherlands. And
                  from there it was basically the process of getting to know
                  each other.
                </p>
                <p>
                  In terms of YES!Delft, we actually attended one of their info
                  sessions. They have a process where you have to first go
                  through a startup scout and then the startup scout invites you
                  to an info session where you get to meet with other startups.
                  It’s also an opportunity to join the rest of the startup
                  community for Friday drinks. Next, you go through the startup
                  visa process with them. But that also means you need to join a
                  program like we did with the Validation Lab. Other than that,
                  it was very straightforward and they supported us very well.
                </p>
                <p>
                  These days all the facilitator information is online. I was
                  able to do everything while I was in Sri Lanka, in terms of
                  conceptualization, but I still knew that we needed to try our
                  best to get to the Netherlands. So I got to the Netherlands
                  and met these different individuals.
                </p>
                <h3>Founder Background</h3>
                <p>
                  I have a Master's of Science in Management from Cranfield, the
                  key lessons that I've learned from my graduate studies that
                  help me in my day-to-day life as a CEO and founder of this
                  company were a lot of soft skills, which helped with
                  confidence, managing people under pressure, working with
                  teams, exploring and handling project management which really
                  helped with conceptualizing, creating, and running a company.
                </p>
                <p>
                  But obviously that's just the initial part, then comes the
                  next stage where you need to start growing and expanding.
                  That's when things get really interesting. When it's dictated
                  by the team itself and having a tight-knit family. It's like a
                  very big family. And maybe now, because of COVID-19, things
                  will change when it comes to office culture. But overall, for
                  me, it was the conditions that gave me the ability to take
                  things forward and grow stronger.
                </p>
                <h3>The Language Barrier</h3>
                <p>
                  When you go to a different country as a founder, you need to
                  know one thing: you need to be able to sell effectively on
                  your own. No one else is going to sell for you. Not your
                  family, not your friends, no one is going to be around. So if
                  you can't sell effectively on your own, it's going to be very
                  hard. Even if you're a technical founder, you need to be able
                  to sell. It's just a must.
                </p>
                <p>
                  And then it gets trickier, because even though the Netherlands
                  has one of the highest English as a second language speaking
                  rates in the world, it’s still predominantly a Dutch-speaking
                  country. You’re going to find language issues with certain
                  types of people who you sell to or who you work with. And
                  that’s something we took a year to learn and figure out
                  because our founding team isn’t made up of Dutch-speaking
                  individuals. So we rely a lot on our team to work with us and
                  to communicate well with our customers, because many of our
                  customers prefer speaking and engaging in Dutch.
                </p>
                <p>
                  If by chance someone had told us a year ago, "You know, it's
                  going to be quite hard for you to sell because your customers
                  are going to be speaking Dutch. They’re going to find it
                  difficult to understand you," I would have tried my best to go
                  to England or some other country for a startup visa. It was a
                  game changer we had to learn the hard way.
                </p>
                <p>
                  Our facilitator from the Hague Business Agency, was able to
                  come with us and introduce us to different restaurants and
                  help us with our validation. So it was great to have this
                  gentleman take time out of his day and take us to certain
                  restaurants or beach clubs and allow us to speak to the
                  owners. And to know that the problems we were trying to solve
                  were at the top of the list of the problems the restauranteur
                  was trying to solve was great for validation.
                </p>
                <h3>Product Development</h3>
                <p>
                  We did a technology proof of concept in Sri Lanka. And then we
                  moved to the Netherlands. It was us exploring a market as
                  business individuals because we, both the founders, aren’t
                  coders. We’re business guys rather than tech guys.
                </p>
                <p>
                  So that being said, we had to rely on a different team to
                  build the product after we conceptualized the product. We
                  helped with the design and architecture, the UI and UX and all
                  that. But the main thing which needed to be done was to make
                  sure we found the right market to sell the product in, and
                  that's what we were working on. So even without a product, we
                  were still going to restaurants to be validated. The solution
                  was being validated by our meetings with restaurant owners and
                  stakeholders, and different types of suppliers.
                </p>
                <p>
                  My business partner Bhagya has been working in the food tech
                  space for six years. So he has extensive experience working
                  with restaurants and building tech platforms for restaurants.
                  Whilst working with his previous startup, he has reviewed over
                  350 restaurants in Asia. In general, we wanted to pursue the
                  idea of contactless ordering and payments because we were fed
                  up of waiting for waiters, and that's what sparked the idea.
                </p>
                <h3>The Pivot</h3>
                <p>
                  But when we came to the Netherlands and started speaking to
                  the restaurant owners, we realized that they didn't want us to
                  get rid of their waiters; they've always liked the waiters.
                  They said the waiters were a good part of the dining
                  experience at these restaurants. So basically, we were able to
                  understand that we needed to change our proposition from
                  reducing costs through automation, with labor being one of the
                  highest costs, and instead help to improve revenue. And that's
                  where we pivoted towards focusing on drinks and snacks —
                  selling high-margin items in order to increase revenue at
                  these locations.
                </p>
                <p>
                  The Netherlands has a very strong beach culture. People would
                  sit at beach beds, but they didn’t want to have to walk to the
                  nearest restaurant or beach club to get a beer or some lunch.
                  As people looked to simply relax by the beach, they just
                  wanted to be able to get the food and drinks delivered to
                  them. So we were able to figure out that this was the biggest
                  problem in the beach bed market, and solve that over the
                  summer of 2019 in the Hague.
                </p>
                <h3>Trade Shows</h3>
                <p>
                  In the Netherlands, Horeca means house, hotels, restaurants
                  and catering. That's the general term for hospitality here in
                  Europe. Before we were able to handle merchant payments as
                  restauranteurs were not comfortable doing business with an
                  unknown party that they didn't trust, hence we started
                  exploring different trade shows in the Netherlands to improve
                  our brand image.
                </p>
                <p>
                  We started visiting them with the help of the facilitators.
                  Because we were an interactive platform, we wanted to be able
                  to interact with these attendees who would become our actual
                  customers, i.e. the attendees of these trade shows are
                  restaurant owners or waiters or managers. We wanted them to be
                  able to order from the vendors of the trade show itself while
                  attending. So you would have different lounge areas and then
                  you would have the QR code in these lounge areas and an
                  attendee could order items from the different vendors that
                  were there. And waiters in the area would receive the order
                  and then dispatch that order to the customer.
                </p>
                <p>
                  Horecava was a 4-day event with 70,000 people, and we
                  officially launched Urbandine at Horecava in January 2020. We
                  weren’t the official sponsors or anything like that, but we
                  were able to provide automated ordering for attendees who were
                  walking through and wanted to just relax and sit in the lounge
                  area of the Innovation Lab of Horecava.
                </p>
                <h3>The Product</h3>
                <p>
                  Urbandine is an in-restaurant or on-premise platform, which
                  helps restaurants get back on their feet right now during
                  COVID-19 because they need to maintain contactless engagement.
                  Urbandine takes away the need for having a physical menu,
                  having waiters handle orders, and having waiters handle
                  payments, so that the waiters can actually focus more time on
                  hospitality, which is what they want to do and what customers
                  want the waiters to do. Behind the scenes, in the kitchen we
                  also streamline the receiving of orders and manages that
                  through tablets as well as printers. We are basically an
                  add-on to the existing system of the restaurant itself.
                </p>
                <p>
                  Remember the beach delivery I mentioned earlier? It's not a
                  delivery to a random place on the beach. Each beach bed would
                  be its own ordering point with a QR code and some
                  instructions. Then the waiters know where those individuals
                  are because they know that the beach bed is #14, and beach bed
                  #14 has ordered a set of two beers, for example.
                </p>
                <p>
                  So prior to the crisis, focus was on high-margin items, which
                  the restaurants would then be able to make more profit on. For
                  example, drinks and snacks are the high-margin items in the
                  Netherlands. Restaurateurs have a saying that ‘drinks bring
                  money and food takes money’. So that's why they were very
                  interested in improving their ability to increase revenue
                  through high-margin items or, frankly, to allow customers to
                  order another round of beer without waiting for it. And now
                  due to COVID-19 we've pivoted focus on the total menu and
                  total automation of the restaurants themselves.
                </p>
                <h3>Parting Note</h3>
                <p>
                  We're often asked how we immigrated all the way from Sri Lanka
                  and started a company in the Netherlands. The catch is that
                  people like us don't actively look and believe that it’s
                  possible. Ambitious founders should understand it is very
                  possible regardless of how difficult it might seem. It would
                  be a game-changer for any potential founder, even during times
                  like these to understand that they can make anything happen if
                  they work hard, smart, and believe in themselves.
                </p>
              </article>
            </div>
          </div>
          <div id="right-col">
            <div className="contact-card">
              <div className="contact-image">
                <img
                  src={Urbandine}
                  alt="Logo"
                  style={{ transform: "scale(2)", paddingTop: "1rem" }}
                />
              </div>
              <div className="contact-name">Jaikishen and Bhagya</div>
              <div className="contact-bio">Founders of Urbandine</div>
              <div className="contact-links">
                <div className="contact-link-container">
                  <OutboundLink
                    href="https://www.urbandine.nl"
                    rel="noreopen noreferer"
                    className="contact-link"
                  >
                    <div className="contact-icon-container">
                      <ExternalLink className="contact-icon" />
                    </div>
                    Urbandine
                  </OutboundLink>
                  <OutboundLink
                    href="https://www.linkedin.com/company/urbandine/"
                    rel="noreopen noreferer"
                    className="contact-link"
                  >
                    <div className="contact-icon-container">
                      <ExternalLink className="contact-icon" />
                    </div>
                    LinkedIn
                  </OutboundLink>
                </div>
              </div>
            </div>
            <AniLink fade to="/startup-visas/netherlands" duration={0.4}>
              <div className="long-container">
                <div className="item-header">
                  <div className="long-icon-container">
                    <Globe className="slack-icon" />
                  </div>
                  Get Startup Visa
                  <div className="item-arrow">→</div>
                </div>
                <div className="item-description"></div>
              </div>
            </AniLink>
          </div>
        </div>

        <div className="interview-body"></div>
      </Layout>
    )
  }
}
